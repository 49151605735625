@tailwind base;
@tailwind components;
@tailwind utilities;

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #242526 #3a3b3c;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: #242526;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #3a3b3c;
  border-radius: 14px;
  border: 3px solid #242526;
}
